import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";

const generateTableRows = ["one", "two", "three", "four", "five"];

const TableLoader = ({ columns = 2 }) => {
  const generateColumns = new Array(columns).fill(null);

  return generateTableRows.map((row) => (
    <TableRow key={row}>
      {generateColumns.map((column, index) => (
        <TableCell component="th" scope="row" key={index}>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ));
};

export default TableLoader;
