import { useState, useRef, useEffect } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import "./index.css";

const MAX_SIZE_ALLOWED = 3;
const FILE_MIMES = ["png", "jpg", "jpeg"];

const DragDropFile = ({
  onFilesChangeHandler = () => {},
  selectedFiles = [],
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [areFileMimesViolated, setAreFileMimesViolated] = useState(false);
  const [isMaxAllowedUploadSizeExceeded, setIsMaxAllowedUploadSizeExceeded] =
    useState(false);

  const [filesSize, setFilesSize] = useState(0);

  const onFilesSizeChangeHandler = (size) => {
    setFilesSize(size);
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const { files } = e.dataTransfer;
      let names = [];
      let size = 0;
      let types = [];
      const numberOfFiles = files.length;

      setAreFileMimesViolated(false);
      setIsMaxAllowedUploadSizeExceeded(false);

      // validate required file input
      if (numberOfFiles === 0) {
        onFilesChangeHandler(null);

        return;
      }

      for (let index = 0; index < numberOfFiles; index++) {
        names.push(files[index].name);
        size += files[index].size;

        let type = files[index].name.split(".");

        types.push(type[type.length - 1]);
      }

      // validate the types of files chosen
      let areTypesValid = true;

      types.forEach((type) => {
        if (!FILE_MIMES.includes(type)) {
          areTypesValid = false;

          return;
        }
      });

      if (!areTypesValid) {
        setAreFileMimesViolated(true);

        onFilesChangeHandler(null);

        return;
      }

      //   setAreFileMimesViolated(false);

      // validate max files upload size
      const fileSizeInMBs = (size / (1000 * 1000)).toFixed(3);

      if (fileSizeInMBs > MAX_SIZE_ALLOWED) {
        setIsMaxAllowedUploadSizeExceeded(true);

        onFilesChangeHandler(null);

        return;
      }

      //   setIsMaxAllowedUploadSizeExceeded(false);

      // validation passed
      onFilesChangeHandler(files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (event) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const { files } = event.target;
      let names = [];
      let size = 0;
      let types = [];
      const numberOfFiles = files.length;

      // validate required file input
      if (numberOfFiles === 0) {
        onFilesChangeHandler(null);

        return;
      }

      for (let index = 0; index < numberOfFiles; index++) {
        names.push(files[index].name);
        size += files[index].size;

        let type = files[index].name.split(".");

        types.push(type[type.length - 1]);
      }

      // validate the types of files chosen
      let areTypesValid = true;

      types.forEach((type) => {
        if (!FILE_MIMES.includes(type)) {
          areTypesValid = false;

          return;
        }
      });

      if (!areTypesValid) {
        setAreFileMimesViolated(true);

        onFilesChangeHandler(null);

        return;
      }

      setAreFileMimesViolated(false);

      // validate max files upload size
      const fileSizeInMBs = (size / (1000 * 1000)).toFixed(3);

      if (fileSizeInMBs > MAX_SIZE_ALLOWED) {
        setIsMaxAllowedUploadSizeExceeded(true);

        onFilesChangeHandler(null);

        return;
      }

      setIsMaxAllowedUploadSizeExceeded(false);

      // validation passed
      onFilesChangeHandler(files);
    }
  };

  const onRemoveHandler = (index) => {
    let remaining = [...selectedFiles];

    remaining.splice(index, 1);

    if (Array.isArray(remaining) && remaining.length === 0) {
      return onFilesChangeHandler(null, "remove");
    }

    onFilesChangeHandler(remaining, "remove");
  };

  useEffect(() => {
    let totalFileSize = 0;

    Array.from(selectedFiles).forEach((file) => {
      const size = file.size;

      totalFileSize += size;
    });

    const fileSizeInMBs = (totalFileSize / (1000 * 1000)).toFixed(3);

    onFilesSizeChangeHandler(fileSizeInMBs);
  }, [selectedFiles]);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <div
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={true}
          onChange={handleChange}
        />

        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? "drag-active" : ""}
        >
          <div>
            <p>Drag and drop your images here or</p>

            <button
              className="upload-button"
              onClick={onButtonClick}
              type="button"
            >
              Upload an image
            </button>
          </div>
        </label>

        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>

      <div>
        <p className="file-info">Maximum file size is: 3 MB</p>

        <Tooltip title={"Size: " + filesSize + " MB"}>
          <LinearProgress
            variant="determinate"
            value={(filesSize * 100) / MAX_SIZE_ALLOWED}
          />
        </Tooltip>

        {selectedFiles?.length > 0 && (
          <>
            <p className="selected-files">Selected files</p>

            <ul className="list">
              {Array.from(selectedFiles).map((file, index) => (
                <li key={index}>
                  <span>{file.name}</span>{" "}
                  <Tooltip title="Remove image">
                    <IconButton
                      aria-label="remove image"
                      onClick={onRemoveHandler.bind(this, index)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </li>
              ))}
            </ul>
          </>
        )}

        {areFileMimesViolated && (
          <p className="violation-message">
            Only{" "}
            {FILE_MIMES.map((mime) => (
              <span key={mime}>.{mime + " "}</span>
            ))}
            files are allowed
          </p>
        )}

        {isMaxAllowedUploadSizeExceeded && (
          <p className="violation-message">
            Max size of {MAX_SIZE_ALLOWED} MB is allowed{" "}
          </p>
        )}
      </div>
    </div>
  );
};

export default DragDropFile;
