import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  useLocation,
} from "react-router-dom";

import usePageViews from "../hooks/usePageViews";
import { ADMIN } from "./userRole";
import { useUser } from "../contexts/UserContext";

import {
  Loader,
  ProtectedRoute,
  HidePublicRouteForAuthUser,
  ShowSidebarForAuthUser,
  ResponsiveDrawer,
} from "../components";

// Error pages
const NotFound = lazy(() => import("../pages/error/NotFound"));
const Unauthorized = lazy(() => import("../pages/error/Unauthorized"));

// Auth pages
const Login = lazy(() => import("../pages/public/auth/Login"));
const ForgotPassword = lazy(() =>
  import("../pages/public/auth/ForgotPassword")
);
const ResetPassword = lazy(() => import("../pages/public/auth/ResetPassword"));
const Register = lazy(() => import("../pages/public/auth/Register"));
const VerifyRegistration = lazy(() =>
  import("../pages/public/auth/VerifyRegistration")
);
const SuccessfulRegistration = lazy(() =>
  import("../pages/public/auth/SuccessfulRegistration")
);

const ChangePassword = lazy(() =>
  import("../pages/private/auth/ChangePassword")
);


// Public pages
const Home = lazy(() => import("../pages/public/Home"));
const About = lazy(() => import("../pages/public/About"));
const Contact = lazy(() => import("../pages/public/Contact"));
const Compatibility = lazy(() => import("../pages/public/Compatibility"));
const FAQs = lazy(() => import("../pages/public/FAQs"));
const ViewBeaconProfile = lazy(() =>
  import("../pages/public/ViewBeaconProfile")
);
const TermsAndConditions = lazy(() =>
  import("../pages/public/TermsConditions/terms")
);

// Private pages
const Dashboard = lazy(() => import("../pages/private/Dashboard"));
const Referrals = lazy(() => import("../pages/private/Referrals"));
const Teams = lazy(() => import("../pages/private/Teams"));
const ViewProfiles = lazy(() =>
  import("../pages/private/profile/ViewProfiles")
);
const AddProfile = lazy(() => import("../pages/private/profile/AddProfile"));
const EditProfile = lazy(() => import("../pages/private/profile/EditProfile"));
const QrCodeScreen = lazy(() =>
  import("../pages/private/profile/QrCodeScreen")
);

const PreviewProfile = lazy(() =>
  import("../pages/private/profile/PreviewProfile")
);
const Users = lazy(() => import("../pages/private/Users"));

const allowedRoutes = [
  "/dashboard",
  "/referrals",
  "/users",
  "/teams",
  "/profile",
  "/profile/add",
  "/profile/:profileCode",
  "/profile/:profileCode/edit",
  "/password/change",
  "/password/change/reset",
];

const Root = () => {
  const { user } = useUser();
  const location = useLocation();
  usePageViews();

  if (user) {
    if (allowedRoutes.some((route) => location.pathname.startsWith(route))) {
      return (
        <ResponsiveDrawer>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </ResponsiveDrawer>
      );
    } else {
      return (
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      );
    }
  }

  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      {/* Public Pages */}
      <Route index element={<Home />} />

      <Route path="/about" element={<About />} />

      <Route path="/contact" element={<Contact />} />

      <Route path="/Compatibility" element={<Compatibility />} />

      <Route path="/frequently-asked-questions" element={<FAQs />} />

      <Route
        path="/public/beacon/:beaconCode"
        element={<ViewBeaconProfile />}
      />

      {/* Auth Pages */}
      <Route path="/login" element={<HidePublicRouteForAuthUser />}>
        <Route index element={<Login />} />
      </Route>

      <Route path="/password/forgot" element={<ForgotPassword />} />

      <Route path="/password/reset" element={<ResetPassword/>} />

      <Route path="/password/change" element={<ProtectedRoute isRestricted />}>
        <Route index element={<ChangePassword />} />
      </Route>

      <Route path="/password/change/reset" element={<ProtectedRoute isRestricted />}>
        <Route index element={<ResetPassword />} />
      </Route>

      <Route path="/register" element={<HidePublicRouteForAuthUser />}>
        <Route index element={<Register />} />

        <Route path="/register/:referrer" element={<Register />} />
      </Route>

      <Route>
        <Route path="/register" element={<Register />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        
      </Route>

      <Route path="/register/verify" element={<HidePublicRouteForAuthUser />}>
        <Route index element={<VerifyRegistration />} />
      </Route>

      <Route path="/register/verified" element={<HidePublicRouteForAuthUser />}>
        <Route index element={<SuccessfulRegistration />} />
      </Route>

      {/* Private Pages */}
      <Route path="/dashboard" element={<ProtectedRoute isRestricted />}>
        <Route index element={<Dashboard />} />
      </Route>

      <Route path="/referrals" element={<ProtectedRoute isRestricted />}>
        <Route index element={<Referrals />} />
      </Route>

      <Route
        path="/users"
        element={<ProtectedRoute isRestricted scopes={[ADMIN]} />}
      >
        <Route index element={<Users />} />
      </Route>

      <Route path="/teams" element={<ProtectedRoute isRestricted />}>
        <Route index element={<Teams />} />
      </Route>

      <Route path="/profile" element={<ProtectedRoute isRestricted />}>
        <Route index element={<ViewProfiles />} />
      </Route>

      <Route path="/profile/add" element={<ProtectedRoute isRestricted />}>
        <Route index element={<AddProfile />} />
      </Route>

      <Route
        path="/profile/QrCodeScreen/:profileCode/:name"
        element={<ProtectedRoute isRestricted />}
      >
        <Route index element={<QrCodeScreen />} />
      </Route>

      <Route
        path="/profile/:profileCode"
        element={<ProtectedRoute isRestricted />}
      >
        <Route index element={<PreviewProfile />} />
      </Route>

      <Route
        path="/profile/:profileCode/edit"
        element={<ProtectedRoute isRestricted />}
      >
        <Route index element={<EditProfile />} />
      </Route>

      {/* Error Pages */}
      <Route path="/unauthorized" element={<ShowSidebarForAuthUser />}>
        <Route index element={<Unauthorized />} />
      </Route>

      <Route path="/*" element={<ShowSidebarForAuthUser />}>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
);

export default router;
