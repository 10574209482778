import { useState } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";

import Button from "@mui/material/Button";
import { Menu as MaterialMenu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { logo } from "../../assets";

import "./index.css";

import HamburgerButton from "./HamburgerButton";
import Menu from "./Menu";
import { Container, GetStartedButton } from "../index";

const Navbar = () => {
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const navigateToHomepage = () => {
    navigate("/");
  };

  return (
    <nav className="navbar">
      <img
        className="navbar__logo"
        src={logo}
        alt="logo"
        onClick={navigateToHomepage}
        tabIndex="1"
      />

      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>

        <li>
          <a
            id="productsButton"
            href="https://store.netifyhub.com/" 
          >
            <p>Products</p>
          </a>
        </li>

        <li>
          <NavLink to="/about">Company</NavLink>
        </li>

        <li>
          <Button
            id="companyButton"
            aria-controls={open ? "companyMenu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <p>Support</p>
            <KeyboardArrowDownIcon />
          
          </Button>

          <MaterialMenu
            id="companyMenu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "companyButton",
            }}
          >
            <MenuItem onClick={handleClose}>
              <NavLink to="/contact">Contact</NavLink>
            </MenuItem>

            <MenuItem onClick={handleClose}>
              <NavLink to="/compatibility">Compatibility</NavLink>
            </MenuItem>

            <MenuItem onClick={handleClose}>
              <NavLink to="/frequently-asked-questions">FAQs</NavLink>
            </MenuItem>

            <MenuItem onClick={handleClose}>
              <Link to="/#how-it-works">How it works</Link>
            </MenuItem>
          </MaterialMenu>
        </li>

      </ul>

      <div className="display-desktop">
        <div className="signIn-div">
          <NavLink className='loginButton' to="/login">Sign in</NavLink>
          
          <GetStartedButton
            arrowColor={'#662d91'} 
            arrowDivColor={'white'}
            background={'#662d91'}
            titleColor={'white'}
            onClick={()=>navigate('/login')}
            style={{padding:"0 8px"}}
          />        
        </div>
      </div>
      

      <HamburgerButton handleHamburgerButton={handleToggleMenu} />

      {toggleMenu && <Menu handleToggleMenu={handleToggleMenu} />}
    </nav>
  );
};

export default Navbar;
