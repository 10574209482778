const styles = {
  container: {
    marginBottom: "24px",
  },
  title: {
    color: "#262250",
    fontWeight: "600",
    fontSize: "24px",
  },
};

export default styles;
