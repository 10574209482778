import { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./styles";

const ContentExpander = ({ data = [], isExpanded = true }) => {
  const [expanded, setExpanded] = useState(isExpanded ? "panel0" : false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return data.map((row, index) => (
    <Accordion
      key={index}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}a-content`}
        id={`panel${index}a-header`}
      >
        <Typography sx={styles.title}>{row?.title}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Typography sx={styles.content}>{row?.content}</Typography>
      </AccordionDetails>
    </Accordion>
  ));
};

export default ContentExpander;
