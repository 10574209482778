const styles = {
  section: {
    padding: "0 24px",
  },
  container: {
    width: "80%",
    maxWidth: "1280px",
    margin: "0 auto",
  },
  fullContainer: {
    width: "100%",
  },
};

export default styles;
