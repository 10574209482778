import { toast } from "react-toastify";

import axiosInstance from "../config/axiosInstance";
import { statsGroupAndCountByHourWithDateSorted } from "../helpers";

export const viewBeaconProfile = async (code, deviceId, password) => {
  try {
    const response = await axiosInstance.post("/guest/beacons/view", {
      code: code,
      password: password,
      deviceID: deviceId,
    });

    if (response.data && response.data.error) {
      // toast(response.data.error.message, { type: "error" });

      if (!response.data.error?.data) {
        return {
          isPasswordProtected: true,
        };
      }

      return null;
    }

    return response.data.success.data;
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const activateBeacon = async (beaconCode, activationCode) => {
  try {
    const response = await axiosInstance.post("/user/beacons/activate", {
      beacon_code: beaconCode,
      activation_code: activationCode,
    });

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      if (response.data.error?.data?.expired) {
        return {
          isTokenExpired: true,
        };
      }

      return null;
    }

    return { acknowledged: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const attachBeaconToProfile = async (profileCode, beaconCode) => {
  try {
    const response = await axiosInstance.post("/user/beacons/profile/attach", {
      profile_code: profileCode,
      beacon_code: beaconCode,
    });

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      if (response.data.error?.data?.expired) {
        return {
          isTokenExpired: true,
        };
      }

      return null;
    }

    return { acknowledged: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const dettachBeaconFromProfile = async (profileCode, beaconCode) => {
  try {
    const response = await axiosInstance.post("/user/beacons/profile/dettach", {
      profile_code: profileCode,
      beacon_code: beaconCode,
    });

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      if (response.data.error?.data?.expired) {
        return {
          isTokenExpired: true,
        };
      }

      return null;
    }

    return { acknowledged: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const beaconInvite = async (beaconCode, email) => {
  try {
    const response = await axiosInstance.post("/user/beacons/invite", {
      beacon_code: beaconCode,
      username: email,
    });

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      if (response.data.error?.data?.expired) {
        return {
          isTokenExpired: true,
        };
      }

      return null;
    }

    if (response.data && response.data.success) {
      toast(response.data.success.message, { type: "success" });
    }

    return { acknowledged: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const viewBeaconStats = async (
  dateFrom,
  dateTo,
  beaconCode,
  page,
  pageSize
) => {
  try {
    const response = await axiosInstance.post("/user/beacons/stats", {
      beacon_code: beaconCode,
      fromDate: dateFrom,
      toDate: dateTo,
      page: page,
      pageSize: pageSize,
    });

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      if (response.data.error?.data?.expired) {
        return {
          isTokenExpired: true,
        };
      }

      return null;
    }

    const data = response.data.success.data;

    let labels = [];
    let datasets = [];
    let columns = [];

    Array.isArray(data) &&
      data.forEach((row) => {
        const obj = {};
        labels.push(
          new Date(row.utc).toLocaleString("en-us", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        );

        datasets.push(row.count);

        obj.date = new Date(row.utc).toLocaleString("en-us", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        });

        obj.count = row.count;

        columns.push(obj);
      });

    return {
      raw: data,
      labels: labels,
      datasets: [
        {
          label: "Count",
          data: datasets,
          backgroundColor: "rgba(102, 45, 145, 0.5)",
        },
      ],
      columns: columns,
      pagination: {
        page: response.data.page,
        pageSize: response.data.pageSize,
        nextPage: response.data.nextPage,
        foundCount: response.data.foundCount,
        totalCount: response.data.totalCount,
      },
      groupedData: statsGroupAndCountByHourWithDateSorted(data),
    };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const getUserBeacons = async (page = 1, pageSize = 100) => {
  try {
    const response = await axiosInstance.post("/user/beacons/find", {
      page,
      pageSize,
    });

    if (response.data?.error) {
      toast(response.data.error.message, { type: "error" });

      if (response.data.error?.data?.expired) {
        return {
          isTokenExpired: true,
        };
      }

      return null;
    }

    const data = response.data.success.data;

    return data;
  } catch (error) {
    if (error.response) {
      toast(error.response?.data?.error?.message || "Something went wrong", {
        type: "error",
      });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const beaconContactRequest = async (
  code,
  name,
  phone,
  email,
  jobAtCompany,
  industry,
  message,
  captcha
) => {
  try {
    const response = await axiosInstance.post("/guest/beacons/contact", {
      code,
      name,
      phone,
      email,
      jobAtCompany,
      industry,
      message,
      "g-recaptcha-response": captcha,
    });

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      return null;
    }

    if (response.data && response.data.success) {
      toast(response.data.success.message, { type: "success" });
    }

    return { acknowledged: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const getContactsList = async (
  page = 1,
  pageSize = 10,
  deviceID = "",
  code = ""
) => {
  try {
    const response = await axiosInstance.post("/user/beacons/contactus", {
      code,
      deviceID,
      page,
      pageSize,
    });

    if (response.data?.error) {
      toast(response.data.error.message, { type: "error" });

      if (response.data.error?.data?.expired) {
        return {
          isTokenExpired: true,
        };
      }

      return null;
    }

    const data = {
      contacts: response?.data?.success?.data || [],
      pagination: {
        page: response.data.page,
        pageSize: response.data.pageSize,
        nextPage: response.data.nextPage,
        foundCount: response.data.foundCount,
        totalCount: response.data.totalCount,
      },
    };

    return data;
  } catch (error) {
    if (error.response) {
      toast(error.response?.data?.error?.message || "Something went wrong", {
        type: "error",
      });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};
