import { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";

import "./index.css";

import { 
  ADMIN, 
  CUSTOMER, 
  DEFAULT 
} from "../../config/userRole";
import { hasCommonScope } from "../../helpers";
import { useUser } from "../../contexts/UserContext";
import { useTheme } from "@mui/material/styles";

import {
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
} from "@mui/material";

import {
  Menu as MenuIcon,
  SpaceDashboardRounded as DashboardIcon,
  AccountCircleRounded as ProfileIcon,
  StyleRounded as ReferralsIcon,
  LockRounded as PasswordIcon,
  PeopleRounded as UsersIcon,
  GroupsRounded as TeamsIcon,
} from "@mui/icons-material";

import { 
  LogoutButton,
  HeadTitle, 
  WhiteSpace, 
} from "../index";

const drawerWidth = 240;

const navigationList = [
  {
    name: "Dashboard",
    link: "/dashboard",
    scopes: [ADMIN, CUSTOMER, DEFAULT],
    icon: <DashboardIcon sx={{ minWidth: 0, mr: 1 }} />
  },
  
  {
    name: "Profile",
    link: "/profile",
    scopes: [ADMIN, CUSTOMER, DEFAULT],
    icon: <ProfileIcon sx={{ minWidth: 0, mr: 1 }} />
  },

  {
    name: "Users",
    link: "/users",
    scopes: [ADMIN],
    icon: <UsersIcon sx={{ minWidth: 0, mr: 1 }} />
  },

  {
    name: "Referrals",
    link: "/referrals",
    scopes: [ADMIN, CUSTOMER, DEFAULT],
    icon: <ReferralsIcon sx={{ minWidth: 0, mr: 1 }} />
  },

  {
    name: "Teams",
    link: "/teams",
    scopes: [ADMIN, CUSTOMER, DEFAULT],
    icon: <TeamsIcon sx={{ minWidth: 0, mr: 1 }} />
  },

  {
    name: "Change Password",
    link: "/password/change",
    scopes: [ADMIN, CUSTOMER, DEFAULT],
    icon: <PasswordIcon sx={{ minWidth: 0, mr: 1 }} />
  },
];

const ResponsiveDrawer = (props) => {
  const { user } = useUser();
  const theme = useTheme();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <Toolbar sx={{ display: "flex", justifyContent: "center", height: 58 }} />

      <List>
        <HeadTitle 
          variant="h3"
          title={"Netify"} 
          style={{color:"black", paddingLeft:"30px"}}        
        />

        <WhiteSpace />

        {navigationList.map((element, index) => {
          return hasCommonScope(element.scopes, user.scopes) ? (
            <Fragment key={index}>
              <ListItem>
                <NavLink 
                  to={element.link} 
                  onClick={() => {
                      setMobileOpen(false);
                    }
                  }
                  className="nav-link"
                >

                  {
                    element.icon
                  }               

                  {
                    element.name
                  } 
                  
                </NavLink>
              </ListItem>
            </Fragment>
          ) : null;
        })}

        <LogoutButton location="drawer navigation" />
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <IconButton
        color="black"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className="drawer-button"
        sx={
          { 
            display: { sm: "none" },
            zIndex: theme.zIndex.drawer + 1,
            position: "absolute",
            top: "10px",
            left: "20px",
          }
        }
      >
        <MenuIcon />
      </IconButton>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          zIndex: (theme) => theme.zIndex.drawer - 101,
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#D9D9D9",
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#D9D9D9",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          pt: 5,
          pb: 6,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: "100vh",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default ResponsiveDrawer;
