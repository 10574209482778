import { useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import LogoutIcon from "@mui/icons-material/Logout";

import { useUser } from "../../../contexts/UserContext";
import { logout } from "../../../services/authService";

import Button from "../../Button";

const LogoutButton = ({ location = "default" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { removeUser } = useUser();

  const handleLogout = async () => {
    try {
      setIsLoading(true);

      await logout();
    } catch (error) {
      console.error("Logout Error:\n", error);
    } finally {
      removeUser();

      setIsLoading(false);
    }
  };

  return (
    <>
      {location === "default" ? (
        <Button title="Logout" onClickHandler={handleLogout} />
      ) : (
        <ListItem>
          <ListItemButton
            onClick={handleLogout}
            sx={
              {
                width: "100%",
                padding: "8px",
                borderRadius: "12px",
                ":hover": {
                  backgroundColor: "#D9D9D9",
                  boxShadow: "0 1px 3px 0 grey, 0 1px 3px 0 grey",
                },
              }
            }
          >
            <ListItemIcon sx={
                { color: "black",
                  minWidth: 0,
                  mr: 1 
                }
              }
            >
              <LogoutIcon />
            </ListItemIcon>

            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      )}

      {isLoading && (
        <Backdrop
          aria-hidden="false"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress size={40} />
        </Backdrop>
      )}
    </>
  );
};

export default LogoutButton;
