import "./index.css";

const Button = ({
  onClickHandler,
  title,
}) => {
  return (
    <button
      onClick={onClickHandler}
      type='button'
      className="button"
    >
      {title}
    </button>
  );
};

export default Button;
