const styles = {
  div: {
    display: "flex",
    marginTop: "16px",
    position: "relative",
    width: "100%",
    maxWidth: "300px",
  },
  imagePreview: {
    objectFit: "cover",
    borderRadius: "150px",
    width: "300px",
    height: "300px",
  },
  label: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "150px",
    padding: "unset",
  },
  plus: {
    position: "absolute",
    bottom: "12px",
    right: "24px",
    width: "48px",
    height: "48px",
    color: "white",
    background: "#353542",
    borderRadius: "100%",
  },
  cropContainer: {
    position: "relative",
    width: "100%",
    height: "300px",
  },
};

export default styles;
