import styles from "./styles";

const PrivatePageTitle = ({ title, removeMargin }) => {
  return (
    <div style={removeMargin ? null : styles.container}>
      <h1 style={styles.title}>{title}</h1>
    </div>
  );
};

export default PrivatePageTitle;
