export const industries = [
  "Aerospace",
  "Agriculture",
  "Automotive",
  "Banking and Finance",
  "Biotechnology",
  "Chemicals",
  "Construction",
  "Education",
  "Energy",
  "Engineering",
  "Entertainment",
  "Environmental",
  "Fashion",
  "Finance and Economics",
  "Food and Beverage",
  "Government and Public Sector",
  "Healthcare",
  "Hospitality",
  "Information Technology",
  "Insurance",
  "Legal",
  "Manufacturing",
  "Media",
  "Mining",
  "Nonprofit",
  "Pharmaceuticals",
  "Real Estate",
  "Retail",
  "Sports and Recreation",
  "Telecommunications",
  "Transportation",
  "Utilities",
  "FMCG",
  "Sales & Distribution"
];
