import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, pr: 7 }} {...other}>
      {children}

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Modal = (props) => {
  const {
    isModalOpen = false,
    modalTitle = "",
    primaryButtonText = "Yes",
    secondaryButtonText = "No",
    handleCloseModal,
    handleSaveButton,
    isDialogActionsVisible = true,
    isSecondaryButtonVisible = true,
    isLoading = false,
    children,
  } = props;

  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      open={isModalOpen}
      fullWidth={true}
    >
      <BootstrapDialogTitle id="modal-title" onClose={handleCloseModal}>
        {modalTitle}
      </BootstrapDialogTitle>

      <DialogContent dividers>{children}</DialogContent>

      {isDialogActionsVisible && (
        <DialogActions>
          {isSecondaryButtonVisible && (
            <Button color="error" variant="outlined" onClick={handleCloseModal}>
              {secondaryButtonText}
            </Button>
          )}

          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleSaveButton}
            loading={isLoading}
          >
            {primaryButtonText}
          </LoadingButton>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};

export default Modal;
