const styles = {
  text: {
    fontSize: "18px",
    lineHeight: "24px",
    color: "black",
    fontWeight: "400",
  },
  
  center: {
    textAlign: "center",
  },
};

export default styles;
