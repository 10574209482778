import { NavLink, Link } from "react-router-dom";

import "./index.css";

const Menu = ({ handleToggleMenu }) => {
  return (
    <div className="menu display-mobile">
      <NavLink className="menu__link" to="/" onClick={handleToggleMenu}>
        Home
      </NavLink>

      <NavLink className="menu__link" to="/about" onClick={handleToggleMenu}>
        Why netify
      </NavLink>

      <NavLink className="menu__link" to="/contact" onClick={handleToggleMenu}>
        Contact
      </NavLink>

      <NavLink
        className="menu__link"
        to="/compatibility"
        onClick={handleToggleMenu}
      >
        Compatibility
      </NavLink>

      <NavLink
        className="menu__link"
        to="/frequently-asked-questions"
        onClick={handleToggleMenu}
      >
        FAQs
      </NavLink>

      <Link
        className="menu__link"
        to="/#how-it-works"
        onClick={handleToggleMenu}
      >
        How it works
      </Link>

      <NavLink
        className="menu__link"
        to="/grow-with-us"
        onClick={handleToggleMenu}
      >
        Grow with us
      </NavLink>

      <NavLink className="menu__link" to="/login" onClick={handleToggleMenu}>
        Login
      </NavLink>

      <button type="button" className="closeButton" onClick={handleToggleMenu}>
        <div className="closeButton_bar1"></div>
        <div className="closeButton_bar2"></div>
      </button>
    </div>
  );
};

export default Menu;
