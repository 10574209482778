import styles from "./styles.js";

const HeadTitle = ({
  variant = "h1",
  title,
  center = false,
  style,
  ...rest
}) => {
  if (variant === "h1") {
    return (
      <h1
        style={{
          ...styles.variantOne,
          ...(center && styles.center),
          ...(style && style),
        }}
        {...rest}
      >
        {title}
      </h1>
    );
  }

  if (variant === "h2") {
    return (
      <h2
        style={{
          ...styles.variantTwo,
          ...(center && styles.center),
          ...(style && style),
        }}
        {...rest}
      >
        {title}
      </h2>
    );
  }

  if (variant === "h3") {
    return (
      <h3
        style={{
          ...styles.variantThree,
          ...(center && styles.center),
          ...(style && style),
        }}
        {...rest}
      >
        {title}
      </h3>
    );
  }

  return (
    <h4
      style={{
        ...styles.variantFour,
        ...(center && styles.center),
        ...(style && style),
      }}
      {...rest}
    >
      {title}
    </h4>
  );
};

export default HeadTitle;
