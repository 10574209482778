import { facebook, instagram } from "../../assets";

import styles from "./styles";

import { Container } from "../index";

const SocialStrip = () => {
  return (
    <Container>
      {/* <div style={styles.wrap}>
        <a href="facebook.com">
          <img src={facebook} alt="facebook" />
        </a>

        <a href="instagram.com">
          <img src={instagram} alt="instagram" />
        </a>
      </div> */}
    </Container>
  );
};

export default SocialStrip;
