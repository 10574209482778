const styles = {
  box: {
    backgroundImage: "linear-gradient(90deg, #662d91 0%, #83cdb8 100%)",
    padding: "48px 0",
    textAlign: "center",
    width: "100%",
  },

  title: {
    color: "white",
    fontWeight: "500",
    fontSize: "40px",
  },
};

export default styles;
