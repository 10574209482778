import { useState, useEffect } from "react";

import { getUserBeacons } from "../../../services/beaconService";
import { useUser } from "../../../contexts/UserContext";

import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Chip,
  Box,
}
from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectCards = ({
  beaconCode = "",
  setBeaconCode = () => {},
  label = "Select Card",
  filter = "teams",
  fullWidth = false,
  style
}) => {
  const { removeUser } = useUser();
  const [data, setData] = useState(null);
  const [, setIsFetching] = useState(false);

  const fetchData = async () => {
    try {
      setIsFetching(true);

      const response = await getUserBeacons();

      setData(response);
    } catch (error) {
      console.error("Get User Beacons Error:\n", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const newValue = typeof value === "string" ? value.split(",") : value;

    setBeaconCode(newValue);
    setBeaconCode(newValue);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!data?.isTokenExpired) return;

    removeUser();
  }, [data?.isTokenExpired, removeUser]);

  return (
    <div>
      <FormControl sx={fullWidth?{width: "100%"}:{width: "190px"} }>
        <InputLabel 
          id="select-card-label" 
          style={{color: "black", fontWeight: "600"}}
        >
          { label }
        </InputLabel>

        <Select
          labelId="select-card-label"
          id="select-card"
          value={beaconCode}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            );
          }}
          sx={style && style}
          MenuProps={MenuProps}
        >
          {data &&
            Array.isArray(data) &&
            data.map((row, index) => {
              if (filter === "teams") {
                if (row.linked && !row.enabled) return null;
              }

              if (filter === "stats") {
                if (!row.enabled) return null;
              }

              return (
                <MenuItem key={index} value={row.beacon_code}>
                  {filter === "stats" ? (
                    <ListItemText
                      primary={row.beacon_code + " - " + row.linkedBy}
                    />
                  ) : (
                    <ListItemText primary={row.beacon_code} />
                  )}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectCards;
