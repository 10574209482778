import "./index.css";

const Button = ({
  onClickHandler,
  title,
  fullWidth = false,
  centered = false,
  type = "button",
}) => {
  return (
    <button
      onClick={onClickHandler}
      className={`customButton ${fullWidth ? "fullWidth" : ""} ${
        centered ? "centered" : ""
      }`}
      type={type}
    >
      {title}
    </button>
  );
};

export default Button;
