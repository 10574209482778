const styles = {
  logoDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },

  logo: {
    width: "84px",
    height: "84px",
  },

  logoText: {
    fontSize: "50px",
  },
}

export default styles;