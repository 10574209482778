import { useState, useCallback } from "react";
import Cropper from "react-easy-crop";

import { getCroppedImg, readFile } from "../../helpers";

import styles from "./styles";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Modal from "../Modal";

const EasyCrop = ({ selectedImage = null, setSelectedImage = () => {} }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, 0);
      setSelectedImage((previousState) => ({
        ...previousState,
        isUpdated: true,
        image: croppedImage,
      }));

      closeModal();
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, setSelectedImage]);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);

      setIsModalOpen(true);
    }
  };

  return (
    <div>
      <Box component="div" sx={styles.div}>
        <Box
          component="img"
          src={selectedImage?.image}
          sx={styles.imagePreview}
        />

        <Button variant="outlined" component="label" sx={styles.label}>
          <input
            hidden
            accept="image/*"
            type="file"
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={onFileChange}
          />

          <AddIcon sx={styles.plus} />
        </Button>
      </Box>

      <Modal
        modalTitle="Crop image (1:1 ratio)"
        isModalOpen={isModalOpen}
        handleCloseModal={closeModal}
        handleSaveButton={showCroppedImage}
        isSecondaryButtonVisible={false}
        primaryButtonText="Save"
      >
        <Box component="div" sx={styles.cropContainer}>
          {imageSrc && (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="round"
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default EasyCrop;
