import "./index.css";

const HamburgerButton = ({ handleHamburgerButton }) => {
  return (
    <div className="hamburgerButton" onClick={handleHamburgerButton}>
      <div className="hamburgerButton_bar1"></div>
      <div className="hamburgerButton_bar2"></div>
      <div className="hamburgerButton_bar3"></div>
    </div>
  );
};

export default HamburgerButton;
