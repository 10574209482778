import styles from "./styles";

import Grid from "@mui/material/Grid";

import { 
  HeadTitle, 
  WhiteSpace 
} from "../";

const ClientsGrid = () => {

  const data = 
    [
      {
        title: "Companies",
        content:
          "Streamline networking, enhance brand visibility, connect with clients and partners.",
      },
      {
        title: "Students",
        content:
          "Share achievements, connect with peers, mentors, explore internships.",
      },
      {
        title: "Shops",
        content:
          "Enable seamless info sharing, personalized offers, engage customers.",
      },
      {
        title: "Solopreneurs",
        content:
          "Boost personal branding, exchange info, create networks for growth.",
      },
      {
        title: "Restaurants",
        content:
          "Simplify reservations, showcase menus, enhance loyalty, utilize reviews and social media.",
      },
      {
        title: "Freelancers",
        content:
          "Display expertise, connect with clients and collaborators.",
      },
      {
        title: "Startups",
        content:
          "Accelerate growth, nurture connections, exchange contact details effortlessly.",
      },
      {
        title: "Professionals",
        content:
          "Simplify networking, strengthen branding, build career networks.",
      },
    ];

  return (
      <Grid container style={styles.container}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index} style={styles.gridItem}>
            <div style={styles.div}>
              <HeadTitle variant="h3" title={item.title} />
              <WhiteSpace height="10px"/>
              <HeadTitle
                variant="h4"
                title = {item.content}
                style={styles.content}
              />
              <WhiteSpace height="20px"/>
            </div>  
          </Grid>
        ))}
      </Grid>
  );
};

export default ClientsGrid;
