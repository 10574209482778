import { createContext, useState, useEffect, useContext } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const VisitorIdContext = createContext();

export const useVisitor = () => {
  return useContext(VisitorIdContext);
};

const VisitorIdProvider = ({ children }) => {
  const [visitorId, setVisitorId] = useState(null);

  useEffect(() => {
    const fetchVisitorId = async () => {
      // Check if the visitorId is already saved in localStorage
      const savedVisitorId = localStorage.getItem("visitorId");

      if (savedVisitorId) {
        // If the visitorId is present in localStorage, use it
        setVisitorId(savedVisitorId);
      } else {
        try {
          const fp = await FingerprintJS.load();
          const result = await fp.get();
          const newVisitorId = result.visitorId;

          // Save the newVisitorId in localStorage
          localStorage.setItem("visitorId", newVisitorId);

          // Update the visitorId state
          setVisitorId(newVisitorId);
        } catch (error) {
          console.error("Error fetching visitorId:", error);
        }
      }
    };

    fetchVisitorId();
  }, []);

  return (
    <VisitorIdContext.Provider value={{ visitorId }}>
      {children}
    </VisitorIdContext.Provider>
  );
};

export { VisitorIdContext, VisitorIdProvider };
