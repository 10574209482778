const styles = {
  stack: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  footer: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  title: {
    fontSize: "20px",
    fontWeight: "600",
  },

  paragraph: {
    fontSize: "14px",
    textDecoration: "none",
    color: "black",
  },

  divider: {
    height: "2px",
    width: "100%",
    backgroundColor: "#662d91",
  },

  copyright: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "400",
  },

  iconDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px"
  },

  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  icon: {
    width: "18px",
  },

  shadow: {
    position: "absolute", 
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
};

export default styles;