import styles from "./styles";

import { WhiteSpace } from "../";

const Card = ({item})=>
{
  return(
    <div style={styles.card}>
      <p>
        {item.opinion}
      </p>
      
      <WhiteSpace height="15px" />

      <b>
        {item.first}
      </b>

      <p>
        {item.second}
      </p>

      <WhiteSpace height="10px" />

      <b>
        {item.third}
      </b>
    </div>
  );
}

const SpecialCard = ({item,})=>
{
  return(
    <div style={styles.specialCard}>
      <p>
        {item.opinion}
      </p>
      
      <WhiteSpace height="15px" />

      <b>
        {item.first}
      </b>

      <p>
        {item.second}
      </p>

      <WhiteSpace height="30px" />

      <div style={styles.whiteShade} ></div>
    </div>
  );
}

const OpinionsGrid = () => {

  const data = 
    [
      {
        opinion: "Every business should use a digital business card!",
        first:
          "James",
        second:
          "Co-founder",
        third:
          "FLASH"
      },
      {
        opinion: "Every business should use a digital business card!",
        first:
          "James",
        second:
          "Co-founder",
        third:
          "FLASH"
      },
      {
        opinion: "Every business should use a digital business card!",
        first:
          "James",
        second:
          "Co-founder",
        third:
          "FLASH"
      },
      {
        opinion: "Every business should use a digital business card!",
        first:
          "James",
        second:
          "Co-founder",
        third:
          "FLASH"
      },
      {
        opinion: "Every business should use a digital business card!",
        first:
          "James",
        second:
          "Co-founder",
        third:
          "FLASH"
      },
      {
        opinion: "Every business should use a digital business card!",
        first:
          "James",
        second:
          "Co-founder",
        third:
          "FLASH"
      },
      
    ];

  return (
      
    <div style={styles.container}>
      <div style={styles.column}>
        <Card item = {data[0]} />
        <Card item = {data[1]} />
      </div>
      <div style={styles.specialColumn}>
        <Card item = {data[2]} />
        <SpecialCard item = {data[3]} />
      </div>
      <div style={styles.column}>
        <Card item = {data[4]} />
        <Card item = {data[5]} />
      </div>
    </div>
    
  );
};

export default OpinionsGrid;
