import useWindowWidth from "../../hooks/useWindowWidth";

import styles from "./styles";

const Container = ({ children }) => {
  const windowWidth = useWindowWidth();

  return (
    <section style={styles.section}>
      <div
        style={
          windowWidth <= 768
            ? (styles.container, styles.fullContainer)
            : styles.container
        }
      >
        {children}
      </div>
    </section>
  );
};

export default Container;
