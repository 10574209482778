import styles from "./styles";

import { NavLink } from "react-router-dom";

import {
  Grid, 
  Typography
} from "@mui/material";

import { WhiteSpace } from "../";

import { 
  MailIcon, 
  PhoneIcon, 
  GreenShadow
} from "../../assets";

const Footer = () => {
  return (
    <div style={styles.stack}>
      <footer style={styles.footer}>
        <Grid container >
          <Grid item xs={12} md={3} lg={3} style={styles.gridItem}>
            <div>
              <Typography sx={styles.title}>Address:</Typography>

              <WhiteSpace height="10px" />

              <Typography sx={styles.paragraph}>Beirut, Lebanon</Typography>

              <WhiteSpace height="10px" />

              <Typography sx={styles.title}>Contact:</Typography>

              <WhiteSpace height="5px" />

              <div style={styles.iconDiv}>
                <img src={PhoneIcon} alt="phone icon" style={styles.icon}/>  
                <Typography sx={styles.paragraph}>+961 70 390 859</Typography>    
              </div>

              <WhiteSpace height="5px" />

              <div style={styles.iconDiv}>
                <img src={MailIcon} alt="mail icon" style={styles.icon}/>  
                <Typography sx={styles.paragraph}>info@netify.com</Typography>    
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={3} lg={3} style={styles.gridItem}>
            <div>
              <Typography sx={styles.title}>Products</Typography>

              <WhiteSpace height="10px" />

              <NavLink style={styles.paragraph} to="">For teams</NavLink>

              <WhiteSpace height="5px" />

              <NavLink style={styles.paragraph} to="">For individuals</NavLink>
            </div>
          </Grid>

          <Grid item xs={12} md={3} lg={3} style={styles.gridItem}>
            <div>
              <Typography sx={styles.title}>Company</Typography>

              <WhiteSpace height="10px" />

              <NavLink style={styles.paragraph} to="/about">About us</NavLink>

              <WhiteSpace height="5px" />

              <NavLink style={styles.paragraph} to="">Careers</NavLink>

              <WhiteSpace height="5px" />

              <NavLink style={styles.paragraph} to="">Newsroom</NavLink>

              <WhiteSpace height="5px" />

              <NavLink style={styles.paragraph} to="/contact">Contact us</NavLink>
            </div>
          </Grid>

          <Grid item xs={12} md={3} lg={3} style={styles.gridItem}>
            <div>
              <Typography sx={styles.title}>Learn more</Typography>

              <WhiteSpace height="10px" />

              <NavLink style={styles.paragraph} to="">Blog</NavLink>

              <WhiteSpace height="5px" />

              <NavLink style={styles.paragraph} to="/frequently-asked-questions">FAQs</NavLink>
            </div>
          </Grid>
        </Grid>

        <WhiteSpace height="64px" />

        <div style={styles.divider} />

        <WhiteSpace height="24px" />

        <Typography sx={styles.copyright} component="p">
          Copyright 2023 | All Rights Reserved
        </Typography>

        <WhiteSpace height="24px" />
      </footer>

      <img src={GreenShadow} style={styles.shadow}/>
    </div>
  );
};

export default Footer;