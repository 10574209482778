const styles = {
  wrap: {
    padding: "12px 0",
    display: "flex",
    gap: "12px",
    justifyContent: "flex-end",
    alignItems: "center",
  },
};

export default styles;
