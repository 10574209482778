import "./index.css";

const Card = ({ fullWidth, children, removeBoxShadow = false, ...rest }) => {
  return (
    <div
      className={`card ${fullWidth ? "fullWidth" : ""} ${
        removeBoxShadow ? "removeBoxShadow" : ""
      }`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Card;
