import { toast } from "react-toastify";

import axiosInstance from "../config/axiosInstance";
import { ADMIN, CUSTOMER } from "../config/userRole";

export const getAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("netifyUser") || "{}");

  if (user && user.accessToken) {
    return user.accessToken;
  }

  return null;
};

export const register = async (
  username,
  email,
  password,
  beaconCode = "",
  referrer = ""
) => {
  try {
    const response = await axiosInstance.post("/user/register", {
      username,
      email,
      password,
      beaconCode,
      referrer,
    });

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      return null;
    }

    if (response.data && response.data.success) {
      toast(response.data.success.message, { type: "success" });
    }

    return { isRegistered: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const verifyRegistration = async (username, code, beaconCode) => {
  try {
    const response = await axiosInstance.post("/user/verify", {
      username,
      code,
    });

    let user = {};

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      return null;
    }

    if (response.data && response.data.success) {
      if (response.data.success.data.access_token) {
        if (response.data.success.data.access_token.user.scopes) {
          let scopes = response.data.success.data.access_token.user.scopes;

          if (scopes.includes(ADMIN)) {
            user.isAdmin = true;
          } else if (scopes.includes(CUSTOMER)) {
            user.isCustomer = true;
          } else {
            user.isDefault = true;
          }

          user.scopes = scopes;
        }

        user.username = response.data.success.data.username;
        user.email = response.data.success.data.access_token.user.email;
        // user.refCode = response.data.success.data.user.refcode;
        user.isLoggedAfterRegistration = true;
        user.beaconCode = beaconCode;
      }

      if (response.data.success.data.access_token) {
        user.accessToken = response.data.success.data.access_token.access_token;
      }

      if (response.data.success.data.username) {
        user.username = response.data.success.data.username;
      }

      toast(response.data.success.message, { type: "success" });
    }

    return user;
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const login = async (username, password) => {
  try {
    const response = await axiosInstance.post("/user/login", {
      username,
      password,
    });

    let user = {};

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "warning" });

      return null;
    }

    if (response.data && response.data.success) {
      if (response.data.success.data.user) {
        if (response.data.success.data.user.scopes) {
          let scopes = response.data.success.data.user.scopes;

          if (scopes.includes(ADMIN)) {
            user.isAdmin = true;
          } else if (scopes.includes(CUSTOMER)) {
            user.isCustomer = true;
          } else {
            user.isDefault = true;
          }

          user.scopes = scopes;
        }

        user.username = response.data.success.data.user.username;
        user.email = response.data.success.data.user.email;
        user.refCode = response.data.success.data.user.refcode;
      }

      if (response.data.success.data.access_token) {
        user.accessToken = response.data.success.data.access_token;
      }

      if (response.data.success.data.username) {
        user.username = response.data.success.data.username;
      }
    }

    return user;
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const requestPasswordReset = async (resetFields) => {
  try {
    const response = await axiosInstance.post("/user/reset", resetFields);

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      return null;
    }

    if (response.data && response.data.success) {
      toast(response.data.success.message, { type: "success" });
    }

    return { isRequested: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const resetPassword = async (resetFields) => {
  try {
    const response = await axiosInstance.post("/user/reset", resetFields);

    if (response.data && response.data.error) {
      toast(response.data.error.message, { type: "error" });

      return null;
    }

    if (response.data && response.data.success) {
      toast(response.data.success.message, { type: "success" });
    }

    return { isReset: true };
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const logout = async () => {
  try {
    const response = await axiosInstance.post("/user/logout");

    return response.data;
  } catch (error) {
    return error;
  }
};
