import styles from "./styles.js";

const BodyText = ({ text, center = false, style, ...rest }) => {
  return (
    <p
      style={{
        ...styles.text,
        ...(center && styles.center),
        ...(style && style),
      }}
      {...rest}
    >
      {text}
    </p>
  );
};

export default BodyText;
