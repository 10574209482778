import styles from "./styles";

const PageTitle = ({ title }) => {
  return (
    <div style={styles.box}>
      <h1 style={styles.title}>{title}</h1>
    </div>
  );
};

export default PageTitle;
