export const testEmailValidation = (email) => {
  const emailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(email);
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = (error) => reject(error);
  });

export const displayLocalDate = (date) => {
  return new Date(date).toLocaleDateString();
};

export const hasCommonScope = (routerScopes, userScopes) => {
  for (const scope of userScopes) {
    if (routerScopes.includes(scope)) {
      return true;
    }
  }

  return false;
};

export const base64ToFile = (base64Image, imageName) => {
  // Extract content type from the Base64 image data
  const contentType = base64Image.substring(
    "data:".length,
    base64Image.indexOf(";base64")
  );

  // Remove the data URL prefix to get the Base64 data
  const base64Data = base64Image.replace(
    /^data:image\/(png|jpeg|jpg);base64,/,
    ""
  );

  // Convert Base64 to ArrayBuffer
  const binaryData = atob(base64Data);
  const length = binaryData.length;
  const buffer = new ArrayBuffer(length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < length; i++) {
    view[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the ArrayBuffer
  const blob = new Blob([buffer], { type: contentType });

  // Create a File object
  const file = new File([blob], imageName + "." + contentType.split("/")[1], {
    type: contentType,
  });

  return file;
};

export const copyToClipboard = (text) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;

  // Make the textarea non-editable to avoid flicker effect
  textarea.setAttribute("readonly", "");

  // Hide the textarea from view
  textarea.style.position = "absolute";
  textarea.style.left = "-9999px";

  document.body.appendChild(textarea);

  // Select and copy the text
  textarea.select();
  document.execCommand("copy");

  document.body.removeChild(textarea);
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  return croppedCanvas.toDataURL("image/png");

  // As a blob
  //   return new Promise((resolve, reject) => {
  //     croppedCanvas.toBlob(
  //       (file) => {
  //         resolve(URL.createObjectURL(file));
  //       },
  //       "image/png",
  //       0.8
  //     );
  //   });
}

export function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export function ensureHttps(link) {
  console.log('linkKKKK', link)
  if (link.startsWith("http://") || link.startsWith("https://")) {
    return link;
  } else {
    return "https://" + link;
  }
}

export const statsGroupAndCountByHourWithDateSorted = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const utcHour = new Date(item.utc).getHours(); // Extract the hour from utc

    // Create a unique key for each hour
    const key = utcHour.toString();

    if (!groupedData[key]) {
      // Initialize the group for this hour if it doesn't exist
      groupedData[key] = {
        utcHour: utcHour,
        count: 0,
        items: [],
        date: "", // Initialize date property
      };
    }

    // Increment the count for this hour
    groupedData[key].count++;

    // Add the item to the items array
    groupedData[key].items.push(item);

    // Set the date property in the desired format
    const date = new Date(item.utc);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    groupedData[key].date = formattedDate;
  });

  // Convert the groupedData object to an array of groups
  const result = Object.values(groupedData);

  // Sort the array based on the date property (oldest to newest)
  result.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });

  return result;
};
