import { createTheme } from "@mui/material/styles";

export const PRIMARY = "#662d91";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY,
    },
  },
});

export default muiTheme;
