import './styles.css';

const ProfileNavbar = ({setIsProfile}) => {  
  const leftClick = ()=>{
    let shadow = document.getElementById('shadow');
    shadow.style.left = '0';
    updateButtonColors();
    setIsProfile(true);
  }

  const rightClick = ()=>{
    let shadow = document.getElementById('shadow');
    shadow.style.left = '50%';
    updateButtonColors();
    setIsProfile(false);
  }
  
  const updateButtonColors = () => {
    let shadow = document.getElementById('shadow');
    let leftButton = document.getElementById('left-button');
    let rightButton = document.getElementById('right-button');
    if (shadow.style.left === '0px') {
        leftButton.style.color = 'white';
        rightButton.style.color = '#643b8f';
    } else {
      rightButton.style.color = 'white';
      leftButton.style.color = '#643b8f';
    }
  }

  return(
    <div className="button-box" >
      <div id="shadow"></div>
      <button id="left-button" className="toggle-button" onClick={leftClick}>PROFILE</button>
      <button id="right-button" className="toggle-button" onClick={rightClick}>REDIRECTION LINK</button>
    </div>
  );
}

export default ProfileNavbar;