import React, { createContext, useEffect, useContext } from "react";
import ReactGA from "react-ga";

const AnalyticsContext = createContext();

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export const AnalyticsProvider = ({ children }) => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

  const trackPageView = (pageName) => {
    ReactGA.pageview(pageName);
  };

  useEffect(() => {
    const trackingId = window.location.pathname + window.location.search;

    ReactGA.pageview(trackingId);
  }, []);

  return (
    <AnalyticsContext.Provider value={{ trackPageView }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContext;
