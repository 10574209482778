import { isIOS } from "react-device-detect";

import useWindowWidth from "../../hooks/useWindowWidth";
import { ensureHttps } from "../../helpers";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import Button from "../Button";

const currentUrl = window.location.href;

const SaveToContacts = ({
  location = "public",
  name="",
  firstName = "",
  lastName = "",
  phone = "",
  secondaryPhone = "",
  email = "",
  secondaryEmail = "",
  address = "",
  company = "",
  profileImage = null,
  socialMedia = {},
  jobAtCompany = "",
  industry = "",
  jobTitle = "",
  bio = "",
  message = "",
}) => {
  const windowWidth = useWindowWidth();

  const handleSave = () => {
    const isLegacyData = !firstName && !lastName;
  
    if (isLegacyData && name) {
      if (name.length <= 50) {
        firstName = name;
        lastName = "";
      } else {
        firstName = "";
        lastName = name;
      }
    }
    
    // Get the contact information from the website
    const contactData = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      secondaryPhone: secondaryPhone,
      email: email,
      secondaryEmail: secondaryEmail,
      address: address,
      company: company,
      profileImage: profileImage,
      socialMedia: socialMedia,
      jobAtCompany: jobAtCompany,
      jobTitle: jobTitle,
      industry: industry,
      bio: bio,
      message: message,
    };
   
    // Filter out empty and null properties
    const filteredContactData = Object.fromEntries(
      Object.entries(contactData).filter(
        ([_, value]) => value !== null && value !== "" && value !== undefined
      )
    );

    let vCard = `BEGIN:VCARD\nVERSION:${isIOS ? "4.0" : "2.1"}\n`;
    let notes = "";
    
    for (const [key, value] of Object.entries(filteredContactData)) {
      if (key === "firstName" || key === "lastName" || key === "isLegacyData") {
        // sorting
        vCard += `N:${filteredContactData.lastName || ""};${filteredContactData.firstName || ""}\n`;
        // display
        vCard += `FN:${filteredContactData.firstName} ${filteredContactData.lastName || ""}\n`;
      
      } else if (key === "phone") {
        vCard += `TEL;TYPE=cell:${value}\n`;
      } else if (key === "secondaryPhone") {
        vCard += `TEL;TYPE=work,voice:${value}\n`;
      } else if (key === "email") {
        vCard += `EMAIL:${value}\n`;
      } else if (key === "secondaryEmail") {
        vCard += `EMAIL:TYPE=WORK:${value}\n`;
      } else if (key === "address") {
        vCard += `ADR;TYPE=WORK,PREF:;;${value}\n`;
      } else if (key === "company") {
        vCard += `TITLE:${value}\n`;
      } else if (key === "profileImage") {
        if (isIOS) {
          vCard += `PHOTO;ENCODING=BASE64;TYPE=JPEG:[${value.split(",")[1]}]\n`;
        } else {
          vCard += `PHOTO;PNG;ENCODING=BASE64:[${value.split(",")[1]}]\n`;
        }
      } else if (key === "socialMedia") {
        for (const [, url] of Object.entries(value)) {
          if (url) {
            vCard += `URL:${ensureHttps(url)}\n`;
          }
        }
      
      } else if (key === "jobAtCompany") {
        if (isIOS) {
          notes += `Job Title At Company: ${value}, `;
        } else {
          vCard += `NOTE:Job Title At Company: ${value}\n`;
        }
      } else if (key === "industry") {
        if (isIOS) {
          notes += `Industry: ${value}, `;
        } else {
          vCard += `NOTE:Industry: ${value}\n`;
        }
      } else if (key === "jobTitle") {
        if (isIOS) {
          notes += `Job Title: ${value}, `;
        } else {
          vCard += `NOTE:Job Title: ${value}\n`;
        }
      } else if (key === "bio") {
        if (isIOS) {
          notes += `Bio: ${value
            .replace(/\n/g, "")
            .replace(/\./g, ". ")
            .trim()}, `;
        } else {
          vCard += `NOTE:Bio: ${value
            .replace(/\n/g, "")
            .replace(/\./g, ". ")
            .trim()}\n`;
        }
      } else if (key === "message") {
        if (isIOS) {
          notes += `Message: ${value}, `;
        } else {
          vCard += `NOTE:Message: ${value}\n`;
        }
      }
    }

    vCard += `URL:${ensureHttps(currentUrl)}\n`;

    if (isIOS) {
      vCard += `NOTE:${notes.replace(/,\s*$/, "")}\n`;
    }
    vCard += "END:VCARD";

    const blob = new Blob([vCard], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = contactData.firstName + ".vcf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return location === "public" ? (
    <Button
      title="Save contact"
      onClickHandler={handleSave}
      fullWidth={windowWidth <= 749 ? true : false}
    />
  ) : (
    <Tooltip title="Save to contacts">
      <IconButton aria-label="save to contacts" onClick={handleSave}>
        <ArrowCircleDownIcon
          color={location === "card" ? "primary" : "action"}
        />
      </IconButton>
    </Tooltip>
  );
};

export default SaveToContacts;
