import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { MuiTelInput } from "mui-tel-input";

import { testEmailValidation } from "../../../helpers";
import { beaconContactRequest } from "../../../services/beaconService";
import { industries } from "../../../config/profileIndustries";

import styles from "./styles";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Modal from "../../Modal";
import WhiteSpace from "../../WhiteSpace";

const formInitialValues = {
  name: "",
  email: "",
  mobile: "",
  jobAtCompany: "",
  industry: "",
  message: "",
};

const validateForm = (values) => {
  const errors = {};
  const { name, email, mobile } = values;

  !name && (errors.name = "Required");
  !mobile && (errors.mobile = "Required");

  if (email) {
    if (!testEmailValidation(email)) {
      errors.email = "Invalid email";
    }
  }

  return errors;
};

const VisitorInfoModal = ({
  isModalOpen = false,
  setIsModalOpen = () => {},
  beaconCode = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleChange,
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: formInitialValues,
    validate: validateForm,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const { name, mobile, email, jobAtCompany, industry, message } = values;

        const response = await beaconContactRequest(
          beaconCode,
          name,
          mobile,
          email.trim(),
          jobAtCompany,
          industry,
          message
        );

        if (response?.acknowledged) {
          closeModal();
        }
      } catch (error) {
        toast(error.message, { type: "error" });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleMobileChange = (value) => {
    setFieldValue("mobile", value);
  };

  const closeModal = () => {
    setIsModalOpen(false);

    resetForm();
  };

  return (
    <Modal
      modalTitle="Exchange contact with me"
      isModalOpen={isModalOpen}
      handleCloseModal={closeModal}
      handleSaveButton={handleSubmit}
      primaryButtonText="Submit"
      secondaryButtonText="Close"
      isLoading={isLoading}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        name="name"
        value={values.name}
        onChange={handleChange}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
        placeholder="Name*"
        autoFocus
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        name="email"
        value={values.email.trim()}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        placeholder="Email"
      />

      <MuiTelInput
        margin="normal"
        required
        fullWidth
        id="mobile"
        name="mobile"
        value={values.mobile}
        onChange={handleMobileChange}
        error={touched.mobile && Boolean(errors.mobile)}
        helperText={touched.mobile && errors.mobile}
        sx={styles.textField}
        placeholder="Mobile*"
        defaultCountry="LB"
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="jobAtCompany"
        name="jobAtCompany"
        value={values.jobAtCompany}
        onChange={handleChange}
        error={touched.jobAtCompany && Boolean(errors.jobAtCompany)}
        helperText={touched.jobAtCompany && errors.jobAtCompany}
        placeholder="Job Title At Company"
        autoFocus
      />

      <WhiteSpace height="16px" />

      <Select
        id="industry"
        name="industry"
        value={values.industry}
        placeholder="Industry"
        onChange={handleChange}
        sx={styles.select}
        displayEmpty
      >
        <MenuItem value="">Industry</MenuItem>

        {industries.map((industry) => (
          <MenuItem key={industry} value={industry}>
            {industry}
          </MenuItem>
        ))}
      </Select>

      <TextField
        margin="normal"
        required
        fullWidth
        id="message"
        name="message"
        value={values.message}
        onChange={handleChange}
        error={touched.message && Boolean(errors.message)}
        helperText={touched.message && errors.message}
        placeholder="Message"
        multiline
        rows={4}
      />
    </Modal>
  );
};

export default VisitorInfoModal;
