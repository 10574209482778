import "./index.css";

const CustomTooltip = ({active, payload}) => {
    if (active && payload) {
        const data = payload[0].payload;

        return (
            <div className="div">
                <p>Taps : <b>{data.count}</b></p>
                <p>{data.date}</p>
            </div>
        );
    }
    
    return null;
};

export default CustomTooltip;