import "./index.css";

import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

const GetStartedButton = ({
  background,
  titleColor,
  title="Get Started",
  arrowColor,
  arrowDivColor,
  onClick ,
  style
}) => {
  return (
    <button
      onClick={onClick}
      type='button'
      className="responsive-button-font-size button"
      style={{...style, background:background, color:titleColor}}
    >

      {title}

      <div className="arrowDiv" style={{backgroundColor:arrowDivColor}}>
        <ArrowForwardIcon style={{fontSize:"18px", color:arrowColor}}/>
      </div>

    </button>
  );
};

export default GetStartedButton;
