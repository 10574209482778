import styles from './styles';

import logo from '../../assets/images/logo.svg';

const NetifyLogo = ({style}) => {

  return(
    <div style={{...(style && style),...(styles.logoDiv) }}>
      <img
        style={styles.logo}
        src={logo}
        alt="logo"
      />
      <p style={styles.logoText}>
        Netify
      </p>
    </div>
  );
}

export default NetifyLogo;
