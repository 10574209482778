import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

const generateCards = ["one", "two", "three", "four", "five"];

const CardsLoader = () => {
  return (
    <Grid container spacing={2}>
      {generateCards.map((row) => (
        <Grid item xs={12} key={row}>
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={194}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsLoader;
