import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AOS from "aos";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import "aos/dist/aos.css";

import App from "./App";
import muiTheme from "./config/muiTheme";

import { UserProvider } from "./contexts/UserContext";
import { VisitorIdProvider } from "./contexts/VisitorContext";
import { AnalyticsProvider } from "./contexts/AnalyticsContext";

AOS.init();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={muiTheme}>
    <VisitorIdProvider>
      <UserProvider>
        <AnalyticsProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ToastContainer position="bottom-right" />

            <App />
          </LocalizationProvider>
        </AnalyticsProvider>
      </UserProvider>
    </VisitorIdProvider>
  </ThemeProvider>
);
