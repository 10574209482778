import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import QrReader from "modern-react-qr-reader";

import { useUser } from "../../../contexts/UserContext";
import {
  activateBeacon,
  attachBeaconToProfile,
} from "../../../services/beaconService";

import TextField from "@mui/material/TextField";

import Modal from "../../Modal";
import WhiteSpace from "../../WhiteSpace";
import useWindowWidth from "../../../hooks/useWindowWidth";

const formInitialValues = {
  profileCode: "",
  beaconCode: "",
  activationCode: "",
};

const validateForm = (values) => {
  const errors = {};
  const { beaconCode, activationCode } = values;

  !beaconCode && (errors.beaconCode = "Required");
  !activationCode && (errors.activationCode = "Required");

  return errors;
};

const ActivateAndAttachBeacon = ({
  profileCode = "",
  isModalOpen = false,
  setIsModalOpen = () => {},
  setBeaconCode = () => {},
}) => {
  const { removeUser, user } = useUser();
  const windowWidth = useWindowWidth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [, setQrData] = useState("No result");

  const {
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: formInitialValues,
    validate: validateForm,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const { profileCode, beaconCode, activationCode } = values;

        let response = await activateBeacon(beaconCode, activationCode);

        if (response?.isTokenExpired) {
          closeModal();
          removeUser();

          return;
        }

        if (response?.acknowledged) {
          if (profileCode) {
            response = await attachBeaconToProfile(profileCode, beaconCode);

            if (response?.isTokenExpired) {
              closeModal();
              removeUser();

              return;
            }

            if (response?.acknowledged) {
              toast("Profile activated and attached", { type: "success" });

              closeModal();

              return;
            }
          }

          setBeaconCode(beaconCode);

          closeModal();
        }
      } catch (error) {
        console.error("Activate & Attach Beacon Error:\n", error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const closeModal = () => {
    setIsModalOpen(false);

    resetForm();

    // navigate("/profile");
  };

  const handleScan = async (scanData) => {
    if (scanData && scanData !== "") {
      setQrData(scanData);

      try {
        const parts = scanData.split("/");
        const code = parts[parts.length - 1] || "";

        if (code) {
          setFieldValue("beaconCode", code);
        }
      } catch (error) {
        console.error("error", error);
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  useEffect(() => {
    if (!profileCode) return;

    setFieldValue("profileCode", profileCode);
  }, [profileCode, setFieldValue]);

  useEffect(() => {
    if (!user?.beaconCode) return;

    setFieldValue("beaconCode", user.beaconCode);
  }, [user?.beaconCode, setFieldValue]);

  return (
    <Modal
      modalTitle="Connect to new card"
      isModalOpen={isModalOpen}
      handleCloseModal={closeModal}
      handleSaveButton={handleSubmit}
      primaryButtonText="Connect"
      secondaryButtonText="Cancel"
      isLoading={isLoading}
    >
      {
        windowWidth < 769 ?
        (
          <>
            <p>Scan qr for tag code</p>

            <WhiteSpace height={8} />

            <QrReader
              delay={300}
              facingMode="environment"
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
            />
          </>
        ) : null
      }

      <TextField
        margin="normal"
        required
        fullWidth
        id="beaconCode"
        name="beaconCode"
        value={values.beaconCode}
        onChange={handleChange}
        error={touched.beaconCode && Boolean(errors.beaconCode)}
        helperText={touched.beaconCode && errors.beaconCode}
        placeholder="Tag code"
        autoFocus
      />

      <TextField
        margin="normal"
        required
        fullWidth
        id="activationCode"
        name="activationCode"
        value={values.activationCode}
        onChange={handleChange}
        error={touched.activationCode && Boolean(errors.activationCode)}
        helperText={touched.activationCode && errors.activationCode}
        placeholder="Activation code"
      />
    </Modal>
  );
};

export default ActivateAndAttachBeacon;
