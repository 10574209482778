import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA with your tracking ID if not already initialized
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
      window.GA_INITIALIZED = true;
    }

    // Track the page view on route change
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

export default usePageViews;
