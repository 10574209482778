const styles = {
  content: {
    fontWeight: "300"
  },

  container : {
    width:"80%",
  },

  gridItem : {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  div : {
    width: "80%",
  },
};

export default styles;