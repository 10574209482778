const styles = {
  title: {
    fontSize: "18px",
    lineHeight: "27px",
    color: "#262250",
    fontWeight: "500",
  },
  content: {
    fontSize: "12px",
    lineHeight: "21px",
    color: "#262250",
  },
};

export default styles;
