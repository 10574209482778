const styles = {
  variantOne: {
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "56px",
  },

  variantTwo: {
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "40px",
  },

  variantThree: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "32px",
  },

  variantFour: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "24px",
  },

  center: {
    textAlign: "center",
  },
};

export default styles;
