import axios from "axios";

import { getAccessToken } from "../services/authService";

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.headers) {
      const accessToken = getAccessToken();

      if (accessToken) {
        config.headers["Authorization"] = "Bearer " + accessToken;
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
