import { Navigate, Outlet } from "react-router-dom";

import { useUser } from "../../contexts/UserContext";

const HidePublicRouteForAuthUser = () => {
  const { user } = useUser();

  if (user) {
    // User just verified his registration -> redirect to profile
    if (user.isLoggedAfterRegistration) {
      return (
        <Navigate to="/profile/add" state={{ isFromRegistration: true }} />
      );
    }

    // Authenticated -> Redirect to dashboard
    return <Navigate to="/dashboard" />;
  }

  // Unauthenticated -> Render the component
  return <Outlet />;
};

export default HidePublicRouteForAuthUser;
