const styles = {
  container : {
    display:'flex',
    flexDirection:"row",
    justifyContent:"center",
    alignItems: 'center',
    margin:"0 15%",
    gap:"15px"
  },

  card: {
    background:"white",
    border:"1px solid #663192",
    borderRadius:"20px",
    padding:"8px",
  },

  specialCard: {
    position: 'relative',
    background:"white",
    border:"1px solid #663192",
    borderTopLeftRadius:"20px",
    borderTopRightRadius:"20px",
    padding:"8px",
    borderBottom:'none',
  },

  column: {
    display:"flex",
    flexDirection:"column",
    gap:"15px",
  },

  specialColumn: {
    display:"flex",
    flexDirection:"column",
    gap:"15px",
    marginTop:"150px",   
  },

  whiteShade: {
    position: 'absolute',
    bottom: '-30px', 
    left:'-1px',
    width:'calc(100% + 2px)',
    height: '60%', 
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    backgroundImage: 'linear-gradient(to top,rgba(245,245,245,0) 10px, rgb(245,245,245) 30px, rgba(255,255,255,0.1) )', 
    pointerEvents: 'none',
  }
};

export default styles;
