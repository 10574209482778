import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";

import { useUser } from "../../../contexts/UserContext";
import { useVisitor } from "../../../contexts/VisitorContext";
import { getContactsList } from "../../../services/beaconService";
import { displayLocalDate } from "../../../helpers";

import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadIcon from "@mui/icons-material/Download";
import DomainIcon from "@mui/icons-material/Domain";

import PrivatePageTitle from "../../PrivatePageTitle";
import SaveToContacts from "../../SaveToContacts";
import WhiteSpace from "../../WhiteSpace";
import TableLoader from "../../TableLoader";
import CardsLoader from "../../CardsLoader";
import Card from "../../Card";

const TransferContactTable = () => {
  const { removeUser } = useUser();
  const { visitorId } = useVisitor();
  const [data, setData] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = async (page, size, deviceId) => {
    try {
      setIsFetching(true);

      const response = await getContactsList(page, size, deviceId);

      setData(response);
    } catch (error) {
      console.error("Get Contacts Error:\n", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(+event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    fetchData(currentPage + 1, pageSize, visitorId);
  }, [currentPage, pageSize, visitorId]);

  useEffect(() => {
    if (!data) return;

    let exportable = [];

    if (data?.contacts) {
      exportable.push([
        "Tag code",
        "Name",
        "Email",
        "Phone",
        "jobAtCompany",
        "Industry",
        "Message",
        "Created At",
      ]);

      data.contacts.forEach((element) => {
        const row = [
          element?.beacon_code || "",
          element?.name || "",
          element?.email || "",
          element?.phone || "",
          element?.jobAtCompany || "",
          element?.industry || "",
          element?.message || "",
          displayLocalDate(element.createdAt),
        ];

        exportable.push(row);
      });
    }

    setCsvData(exportable);
  }, [data]);

  useEffect(() => {
    if (!data?.isTokenExpired) return;

    removeUser();
  }, [data?.isTokenExpired, removeUser]);

  return (
    <div>
      <PrivatePageTitle title="Contacts" />

      <CSVLink
        data={csvData}
        className="csv-export"
        filename={`contacts page(${currentPage + 1}).csv`}
      >
        <DownloadIcon />

        <span>Export data</span>
      </CSVLink>

      <div className="display-mobile">
        {isFetching ? (
          <CardsLoader />
        ) : (
          <>
            <Grid container spacing={2}>
              {data?.contacts?.map((row) => (
                <Grid item xs={12} key={row.uuid}>
                  <Card fullWidth>
                    <Box display="flex" justifyContent="center">
                      <Typography component="h2" variant="h5">
                        {row.name}
                      </Typography>
                    </Box>

                    <WhiteSpace />

                    {row?.email && (
                      <>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title="Email">
                            <AlternateEmailIcon color="disabled" />
                          </Tooltip>

                          <Typography>{row.email}</Typography>
                        </Stack>

                        <WhiteSpace />
                      </>
                    )}

                    {row?.phone && (
                      <>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title="Phone">
                            <PhoneIcon color="disabled" />
                          </Tooltip>

                          <Typography>{row.phone}</Typography>
                        </Stack>

                        <WhiteSpace />
                      </>
                    )}

                    {row?.jobAtCompany && (
                      <>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title="Job Title At Company">
                            <DomainIcon color="disabled" />
                          </Tooltip>

                          <Typography>{row.jobAtCompany}</Typography>
                        </Stack>

                        <WhiteSpace />
                      </>
                    )}

                    {row?.industry && (
                      <>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title="Industry">
                            <DomainIcon color="disabled" />
                          </Tooltip>

                          <Typography>{row.industry}</Typography>
                        </Stack>

                        <WhiteSpace />
                      </>
                    )}

                    {row?.message && (
                      <>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title="Message">
                            <MessageIcon color="disabled" />
                          </Tooltip>

                          <Typography>{row.message}</Typography>
                        </Stack>

                        <WhiteSpace />
                      </>
                    )}

                    {row?.createdAt && (
                      <>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title="Created at">
                            <CalendarMonthIcon color="disabled" />
                          </Tooltip>

                          <Typography>
                            {displayLocalDate(row.createdAt)}
                          </Typography>
                        </Stack>

                        <WhiteSpace />
                      </>
                    )}

                    <Stack direction="row-reverse" spacing={1}>
                      <SaveToContacts
                        location="card"
                        name={row.name}
                        phone={row.phone}
                        email={row?.email ?? ""}
                        jobAtCompany={row?.jobAtCompany ?? ""}
                        industry={row?.industry ?? ""}
                        message={row?.message ?? ""}
                      />
                    </Stack>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <WhiteSpace />

            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(data?.pagination?.totalCount / 10) || 1}
                page={currentPage + 1}
                onChange={(event, newPage) => {
                  handlePageChange(event, newPage - 1);
                }}
              />
            </Stack>
          </>
        )}
      </div>

      <div className="display-desktop">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
              <TableContainer sx={{ maxHeight: 421 }}>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>

                      <TableCell>Email</TableCell>

                      <TableCell>Phone</TableCell>

                      <TableCell>Job Title At Company</TableCell>

                      <TableCell>Industry</TableCell>

                      <TableCell>Message</TableCell>

                      <TableCell>Created At</TableCell>

                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {isFetching ? (
                      <TableLoader columns={7} />
                    ) : (
                      data?.contacts?.map((row) => (
                        <TableRow
                          key={row.uuid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {row.email}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {row.phone}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {row?.jobAtCompany}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {row?.industry}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {row.message}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {displayLocalDate(row.createdAt)}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              spacing={1}
                              justifyContent="flex-end"
                            >
                              <SaveToContacts
                                location="table"
                                name={row.name}
                                phone={row.phone}
                                email={row?.email ?? ""}
                                jobAtCompany={row?.jobAtCompany ?? ""}
                                industry={row?.industry ?? ""}
                                message={row?.message ?? ""}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data?.pagination?.totalCount || 0}
            rowsPerPage={pageSize}
            page={currentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
};

export default TransferContactTable;
